<template lang="pug">
.container
  AppTitle(label="Nuevo Gasto")
  AppCard
    form(@submit.prevent="onSubmit")
      AppFormInput(type="text" label="Nombre" name="name" required)
      AppFormInput(type="textarea" label="Descripcion" name="descripcion" required)
      AppFormInput(type="select" name="category_id" label="Categoria" :options="categories")
      AppRow
        FileUpload(
          icon="map"
          label="PDF"
          name="pdf_file"
          accept="application/pdf"
          required
        )
        FileUpload(
          icon="map"
          label="XML"
          name="xml"
          accept="text/xml"
          required
        )
      AppButton.mt-5(:loading="loading" :disabled="loading" label="Enviar")
</template>

<script>
import AppTitle from "@/components/AppTitle";
import FileUpload from "@/components/FileUpload.vue";
import { AppButton, AppCard, AppFormInput, AppRow } from "gignet-components";
import { ref } from "@vue/reactivity";
import { store } from "@/store";
import { axios } from "@/utils/axios";
import { computed, onMounted } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { onError, onSuccess } from "@/utils/toastify";
export default {
  components: {
    AppButton,
    AppTitle,
    AppCard,
    AppFormInput,
    FileUpload,
    AppRow,
  },
  setup() {
    const hasError = ref(false);
    const router = useRouter();
    const loading = computed(() => store.loading);
    const categories = ref([]);

    onMounted(async () => {
      try {
        store.loading = true;
        const { data } = await axios.get("/categoryexpenses/");
        categories.value = data.payload.reduce(
          (a, e) => [...a, { label: e.name, value: e.id }],
          []
        );
      } catch (error) {
        onError("Intenta mas tarde");
      } finally {
        store.loading = false;
      }
    });

    const formatXml = (xml) => {
      return {
        descuento: xml.comprobante[0].Descuento,
        fecha: xml.comprobante[0].Fecha,
        forma_pago: xml.comprobante[0].FormaPago,
        lugar_expedicion: xml.comprobante[0].LugarExpedicion,
        metodo_pago: xml.comprobante[0].MetodoPago,
        moneda: xml.comprobante[0].Moneda,
        serie: xml.comprobante[0].Serie,
        subtotal: xml.comprobante[0].SubTotal,
        tipo_comprobante: xml.comprobante[0].TipoDeComprobante,
        total: xml.comprobante[0].Total,
        version: xml.comprobante[0].Version,
        regimen_fiscal_emisor: xml.emisor[0].RegimenFiscal,
        rfc_emisor: xml.emisor[0].Rfc,
        nombre_emisor: xml.emisor[0].Nombre,
        rfc_receptor: xml.receptor[0].Rfc,
        nombre_receptor: xml.receptor[0].Nombre,
        uso_cfdi_receptor: xml.receptor[0].UsoCFDI,
        res_lines_concepts: xml.conceptos,
        fecha_timbrado: xml.timbre[0].FechaTimbrado,
        no_certificado_sat: xml.timbre[0].NoCertificadoSAT,
        rfc_prov_certificado: xml.timbre[0].RfcProvCertif,
        uuid: xml.timbre[0].UUID,
      };
    };

    const onSubmit = async (e) => {
      if (store.loading) return;
      store.loading = true;
      hasError.value = false;
      try {
        const body = new FormData(e.target);
        const errors = [];
        for (const key of body) {
          if (typeof key[1] === "string" && key[1] === "") {
            errors.push(key[0]);
          } else if (key[1]?.size === 0) {
            errors.push(key[0]);
          }
        }
        if (errors.length) throw new Error(`Todos los campos son requeridos`);
        const { ["data"]: xml } = await axios.post("expenses/scan", body);

        let formData = formatXml(xml.payload);
        body.forEach((value, key) => (formData[key] = value));
        formData = {
          ...formData,
          xml_file: formData.xml,
          colaborador_id: store.user.id,
        };

        const form_data = new FormData();
        for (var key in formData) {
          form_data.append(key, formData[key]);
        }
        await axios.post("expenses/", form_data);
        onSuccess("Gasto Agregado");
        router.push({ name: "ExpenseIndex" });
      } catch (error) {
        console.log(error);
        onError("Gasto no guardado");
      } finally {
        store.loading = false;
      }
    };
    return { hasError, onSubmit, loading, categories };
  },
};
</script>

<style lang="scss" scoped></style>
